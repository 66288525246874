import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="input-preset"
export default class extends Controller {
  static values = {
    preset: String,
    confirmMessage: String
  }
  static targets = ["input"]

  preset() {
    if (this.inputTarget.value) {
      // 値が入力されている場合は確認ダイアログを表示
      if (!confirm(this.confirmMessageValue)) {
        // キャンセルされた場合は何もしない
        return
      }
    }

    // プリセット値を設定
    this.inputTarget.value = this.presetValue
  }
}
