import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="direct-submit"
export default class extends Controller {
  static targets = ['submitter', 'disableWhileSubmitting']

  submit() {
    /** @type {HTMLFormElement} 送信対象のフォーム要素 */
    const form = this.submitterTarget.form

    // フォーム送信
    form.requestSubmit(this.submitterTarget)

    if (this.hasDisableWhileSubmittingTarget) {
      // 入力フォームを無効化
      this.disableWhileSubmittingTargets.forEach(el => el.disabled = true)

      // フォーム送信が完了したら入力フォームを有効化
      form.addEventListener('turbo:submit-end', () => {
        this.disableWhileSubmittingTargets.forEach(el => el.disabled = false)
      }, { once: true })
    }
  }
}
